import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, List, Modal, Select, Space, Spin, Table } from 'antd';
import _ from 'lodash';
import * as actionCreators from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import openNotification from './Notification';
import { SearchOutlined } from '@ant-design/icons';

const DoNotConvertBrandModal = ({ isVisible, handleCancel }) => {
  const dispatch = useDispatch();
  //selectors
  const nonConvertedBrands = useSelector(state => _.get(state.doNotConvertBrands, 'doNotConvertBrands', []));
  const deletingBrands = useSelector(state => _.get(state.doNotConvertBrands, 'deletingBrands', false));
  const allBrands = useSelector(state => _.get(state.doNotConvertBrands, 'allBrands', []));
  const allBrandsLoading = useSelector(state => _.get(state.doNotConvertBrands, 'fetchingAllBrands', false));
  const newDoNotConvertBrands = useSelector(state => _.get(state.doNotConvertBrands, 'newDoNotConvertBrands', []));
  const isAddingDoNotConvertBrand = useSelector(state =>
    _.get(state.doNotConvertBrands, 'isAddingDoNotConvertBrand', false)
  );
  const fetchingDoNotConvertList = useSelector(state =>
    _.get(state.doNotConvertBrands, 'fetchingDoNotConvertList', false)
  );
  //state
  const [brandList, setBrandList] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [brandsMarkForDelete, setbrandsMarkForDelete] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const [isAddBrandModalVisible, setAddBrandModalVisible] = useState(false);
  const [filteredAllBrands, setFilteredAllBrands] = useState([]);
  const [allBrandSelectedType, setAllBrandSelectedType] = useState('contains');
  const [allBrandSearchText, setAllBrandSearchText] = useState('');
  const [checkedBrands, setCheckedBrands] = useState([]);
  const [visibleItems, setVisibleItems] = useState(10);
  const [totalNonConvertedBrands, setTotalNonConvertedBrands] = useState(0);
  const [allBrandCurrentPage, setAllBrandCurrentPage] = useState(1);
  const [shouldRefreshAllBrands, setShouldRefreshAllBrands] = useState(false);
  const [nonConverBrandSort, setNonConverBrandSort] = useState({});

  const handleDoNotConvertCancel = () => {
    handleCancel(false);
    setDataFetched(false);
    setCheckedBrands(new Set());
    setbrandsMarkForDelete([]);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    console.log(selectedKeys[0] + '----' + dataIndex);
    addPropertyOnFilters(dataIndex, selectedKeys[0]);
  };

  const handleReset = (clearFilters, column) => {
    console.log(column);
    delete searchParams[column];
    clearFilters();
    setSearchText('');
  };

  // when new filters are appending filter object will be updated.
  const addPropertyOnFilters = (key, value) => {
    const currentSearchParams = { ...searchParams };
    // Add or update key-value pairs
    currentSearchParams[key] = value;
    // Update the state with the new searchParams object
    setSearchParams(currentSearchParams);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  });

  const columns = [
    {
      title: 'Brand Code',
      dataIndex: 'brandCode',
      render: text => <a>{text}</a>,
      ...getColumnSearchProps('brandCode'),
      sorter: (a, b) => a.brandCode.localeCompare(b.brandCode)
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      ...getColumnSearchProps('brandName'),
      sorter: (a, b) => a.brandName.localeCompare(b.brandName)
    }
  ];

  useEffect(() => {
    if (isVisible && !dataFetched && !deletingBrands) {
      loadnonConvertedBrandConversionData();
      setDataFetched(true);
      setbrandsMarkForDelete([]);
    }
  }, [dataFetched, isVisible, deletingBrands, newDoNotConvertBrands, currentPage, nonConverBrandSort]);

  useEffect(() => {
    if (isVisible) {
      loadnonConvertedBrandConversionData();
    }
  }, [nonConverBrandSort]);

  useEffect(() => {
    const originalData = nonConvertedBrands?.data?.data;
    setTotalNonConvertedBrands(nonConvertedBrands?.data?.total);
    if (!deletingBrands) {
      if (originalData) {
        const transformedData = originalData.map(item => {
          return {
            key: item.id,
            brandName: item.brandName,
            brandCode: item.brandCode
          };
        });
        setBrandList(transformedData);
      }
    }
  }, [nonConvertedBrands]);

  useEffect(() => {
    if (allBrands && allBrands?.data?.data) {
      setFilteredAllBrands(allBrands?.data?.data);
    }
  }, [allBrands]);

  useEffect(() => {
    if (newDoNotConvertBrands.length > 0) {
      setAddBrandModalVisible(false);
    }
  }, [newDoNotConvertBrands]);

  useEffect(() => {
    // Clear all checked rows when the table is loading
    if (allBrandsLoading) {
      setFilteredAllBrands(filteredAllBrands.map(item => ({ ...item, checked: false })));
    }
  }, [allBrandsLoading]);

  useEffect(() => {
    if (!isAddingDoNotConvertBrand && shouldRefreshAllBrands) {
      loadAllBrandList();
      setShouldRefreshAllBrands(false);
    }
  }, [allBrandCurrentPage, isAddingDoNotConvertBrand]);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: 'Del',
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setbrandsMarkForDelete(selectedRowKeys);
    }
  };

  const loadnonConvertedBrandConversionData = () => {
    dispatch(
      actionCreators.getAllDoNotConvertBrands({
        searchParams: searchParams,
        sortParams: nonConverBrandSort,
        pageNo: currentPage,
        pageSize: 10
      })
    );
  };

  /**
   * delete selected do not convert brands
   */
  const handleDeleteBrand = () => {
    setDataFetched(false);
    dispatch(actionCreators.deleteSelectedDoNotConvertBrands({ ids: brandsMarkForDelete }));
  };

  const handlePaginationChange = (page, pageSize) => {
    // dispatchan error message when records are marked for delete.
    if (brandsMarkForDelete.length > 0) {
      openNotification({
        message: 'Error',
        description: 'Pagination change is not allowed.You have unsaved changes.',
        className: 'error'
      });
      return;
    }
    setCurrentPage(page);
    setDataFetched(false);
  };

  const handlePaginationChangeAllBrand = (page, pageSize) => {
    // dispatchan error message when records are marked for delete.
    if (checkedBrands.length > 0) {
      openNotification({
        message: 'Error',
        description: 'Pagination change is not allowed.You have unsaved changes.',
        className: 'error'
      });
      return;
    }
    setAllBrandCurrentPage(page);
    setShouldRefreshAllBrands(true);
    console.log(pageSize);
  };

  const handleAddBrand = () => {
    setAddBrandModalVisible(true);
    loadAllBrandList();
  };

  const loadAllBrandList = () => {
    let searchParams = {};
    searchParams['brandName'] = allBrandSearchText;
    searchParams['searchType'] = allBrandSelectedType;

    //load all brand modals
    dispatch(
      actionCreators.getAllBrands({
        searchParams: searchParams,
        sortParams: '',
        pageNo: allBrandCurrentPage,
        pageSize: 10
      })
    );
  };

  const handleAddBrandModalCancel = () => {
    setAddBrandModalVisible(false);
    setAllBrandSearchText('');
    setCheckedBrands(new Set());
  };

  const handleAddBrandModalSubmit = () => {
    if (checkedBrands.length > 0) {
      dispatch(actionCreators.addSelectedDoNotConvertBrands({ brands: Array.from(checkedBrands) }));
      setShouldRefreshAllBrands(true);
    } else {
      openNotification({
        message: 'Error',
        description: 'At least one brand must be selected to save.',
        className: 'error'
      });
    }
  };

  const handleTypeChange = value => {
    setAllBrandSelectedType(value);
  };

  const handleTextChange = e => {
    setAllBrandSearchText(e.target.value);
    // filterBrands(allBrandSelectedType, e.target.value);
  };

  const columnsAllBrands = [
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      key: 'brandName'
    }
  ];

  // rowSelection object indicates the need for row selection
  const allBrandsRowSelection = {
    columnTitle: 'Add',
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setCheckedBrands(selectedRows);
    }
  };

  const onChangeNonConverted = (pagination, filters, sorter, extra) => {
    setNonConverBrandSort({});
    if (extra.action === 'sort') {
      if (sorter.order === 'ascend') {
        setNonConverBrandSort({ asc: [sorter.field] });
      } else {
        setNonConverBrandSort({ desc: [sorter.field] });
      }
    }
  };

  return (
    <>
      {/* Modal component - Do Not Convert Brand List */}
      <Modal title="Do Not Convert Brand List" visible={isVisible} onCancel={handleDoNotConvertCancel} footer={null}>
        {/* Use Spin component to show loading indicator */}
        <Spin spinning={fetchingDoNotConvertList || deletingBrands}>
          {/* Modal content */}
          <Table
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            columns={columns}
            dataSource={brandList}
            pagination={{
              total: totalNonConvertedBrands, // Provide the total number of items
              onChange: handlePaginationChange,
              current: currentPage,
              pageSizeOptions: [],
              showSizeChanger: false
            }}
            onChange={onChangeNonConverted}
          />
          {/* Buttons */}
          <div style={{ textAlign: 'right', marginTop: '1rem' }}>
            <Button
              type="primary"
              style={{ marginRight: '1rem' }}
              onClick={handleDeleteBrand}
              disabled={brandsMarkForDelete.length === 0}
            >
              DELETE SELECTED BRAND/S
            </Button>
            <Button type="primary" style={{ marginRight: '1rem' }} onClick={handleAddBrand}>
              ADD BRAND
            </Button>
          </div>
        </Spin>
      </Modal>

      {/* Add Brand Modal */}
      <Modal
        title="ADD BRAND TO DO NOT CONVERT LIST"
        visible={isAddBrandModalVisible}
        onCancel={handleAddBrandModalCancel}
        footer={[
          <Button key="clear" onClick={handleAddBrandModalCancel}>
            Cancel
          </Button>,
          <Button key="Add" type="primary" onClick={handleAddBrandModalSubmit}>
            Submit
          </Button>
        ]}
      >
        {/* Use Spin component to show loading indicator */}
        <Spin spinning={allBrandsLoading || isAddingDoNotConvertBrand}>
          {/* Add Brand Modal Content Goes Here */}
          <div>
            <h3>Search by Containers</h3>
            <Select defaultValue="contains" style={{ width: 120, marginBottom: 16 }} onChange={handleTypeChange}>
              <Select.Option value="contains">Contains</Select.Option>
              <Select.Option value="starts_with">Starts with</Select.Option>
              <Select.Option value="ends_with">Ends with</Select.Option>
            </Select>
            <Input
              placeholder="Type something..."
              style={{ width: 200, marginRight: 16 }}
              onChange={handleTextChange}
            />
            <Button key="Add" type="primary" onClick={handleAddBrand}>
              Search
            </Button>

            <p>Select one or more Brands to add (filtered based on search):</p>
            <Table
              rowSelection={{
                type: 'checkbox',
                ...allBrandsRowSelection
              }}
              dataSource={filteredAllBrands}
              columns={columnsAllBrands}
              pagination={{
                total: allBrands?.data?.total,
                onChange: handlePaginationChangeAllBrand,
                current: allBrandCurrentPage,
                pageSizeOptions: [],
                showSizeChanger: false
              }}
            />
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default DoNotConvertBrandModal;
