import initialState from 'store/initialState';
import { USER } from 'actions/actionTypes';

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case USER.REQUEST:
      return { ...state };
    case USER.SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload
        }
      };
    case USER.FAILURE:
      return {
        ...state,
        fetching: false
      };
    default:
      return state;
  }
};

export default user;
