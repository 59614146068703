import {
  ALL_BRANDS,
  DO_NOT_CONVERT_BRANDS,
  DO_NOT_CONVERT_BRANDS_ADD,
  DO_NOT_CONVERT_BRANDS_DELETE
} from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { deleteRequest, getRequest, postRequest } from '_http';
import { action } from 'reduxHelpers';

// Gets all do not convert brands..
function* fetchDoNotConvertBrandsAsync({ searchParams, sortParams, pageNo, pageSize }) {
  
  try {
    const _sortParams =
      sortParams && Object.keys(sortParams).length > 0
        ? encodeURIComponent(JSON.stringify(sortParams))
        : encodeURIComponent(JSON.stringify({ asc: ['brandName'] }));
    const _filter = searchParams
      ? encodeURIComponent(JSON.stringify(searchParams))
      : encodeURIComponent(JSON.stringify({}));

    const nonConvertBrandList = yield getRequest(
      `/brandconversion/do_not_convert_brands?search-param=${_filter}&sort-params=${_sortParams}&current-page=${
        pageNo && pageNo > 0 ? pageNo - 1 : 0
      }&page-size=${pageSize ? pageSize : 10}`
    );
    console.log('data', nonConvertBrandList);
    yield put({ type: DO_NOT_CONVERT_BRANDS.SUCCESS, payload: { nonConvertBrandList } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: DO_NOT_CONVERT_BRANDS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH NON CONVERTED BRAND DETAILS"
      })
    );
  }
}

// delete selected do not convert brands..
function* deleteDoNotConvertBrandsAsync({ ids }) {
  
  try {
    const nonConvertBrandList = yield deleteRequest(
      `/brandconversion/do_not_convert_brands?id=${encodeURIComponent(JSON.stringify(ids))}`
    );
    console.log('data', nonConvertBrandList);
    yield put({ type: DO_NOT_CONVERT_BRANDS_DELETE.SUCCESS, payload: { nonConvertBrandList } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Selected do not convert brands are deleted successfully.',
        className: 'success',
        message: 'SUCCESS: Deleted Do Not Convert Brands'
      })
    );
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: DO_NOT_CONVERT_BRANDS_DELETE.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH NON CONVERTED BRAND DETAILS"
      })
    );
  }
}

// dget all brands..
function* getAllBrandsAsync({ searchParams, sortParams, pageNo, pageSize }) {
  const _sortParams =
    sortParams && Object.keys(sortParams).length > 0
      ? encodeURIComponent(JSON.stringify(sortParams))
      : encodeURIComponent(JSON.stringify({ asc: ['brandName'] }));
  const _filter = searchParams
    ? encodeURIComponent(JSON.stringify(searchParams))
    : encodeURIComponent(JSON.stringify({}));
  
  try {
    const brandList = yield getRequest(
      `/brandconversion/brands?search-param=${_filter}&sort-params=${_sortParams}&current-page=${
        pageNo && pageNo > 0 ? pageNo - 1 : 0
      }&page-size=${pageSize ? pageSize : 10}`
    );
    console.log('data', brandList);
    yield put({ type: ALL_BRANDS.SUCCESS, payload: { brandList } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: ALL_BRANDS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH BRAND DETAILS"
      })
    );
  }
}

// add selected do not convert brands..
function* addDoNotConvertBrandsAsync({ brands }) {
  
  try {
    const newDoNotConvertBrands = yield postRequest(`/brandconversion/do_not_convert_brands`, brands);
    console.log('data', newDoNotConvertBrands);
    yield put({ type: DO_NOT_CONVERT_BRANDS_ADD.SUCCESS, payload: { newDoNotConvertBrands } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Do not convert brand is added successfully.',
        className: 'success',
        message: 'SUCCESS: Added Do Not Convert Brands'
      })
    );
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: DO_NOT_CONVERT_BRANDS_ADD.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH NON CONVERTED BRAND DETAILS"
      })
    );
  }
}

export { fetchDoNotConvertBrandsAsync, deleteDoNotConvertBrandsAsync, getAllBrandsAsync, addDoNotConvertBrandsAsync };
