import initialState from 'store/initialState';
import { NEW_PAIR } from 'actions/actionTypes';

const newPair = (state = initialState.newPair, action) => {
  switch (action.type) {
    case NEW_PAIR.REQUEST:
      return { ...state, isSavingPair: true,isFaild: false };
    case NEW_PAIR.SUCCESS:
      return {
        ...state,
        isSavingPair: false,
        newPair: { ...state.newPair, newPair: action.payload.response.data },
        isFaild: false
      };
    case NEW_PAIR.FAILURE:
      return { ...state, isSavingPair: true,isFaild: true};
    default:
      return state;
  }
};

export default newPair;
