import { VALID_ATTRIBUTES } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';


// Gets all business centers
function* fetchValidAttributesAsync({ bc, ig, ag, pairType }) {
  try {
    console.log('inside api call pair types');
    const attributes = yield getRequest(`/brandconversion/attributes?bc=${encodeURIComponent(bc)}&ig=${encodeURIComponent(ig)}&ag=${encodeURIComponent(ag)}&pairType=${encodeURIComponent(pairType)}`);
    console.log('data pair types', attributes);
    yield put({ type: VALID_ATTRIBUTES.SUCCESS, payload: { attributes } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: VALID_ATTRIBUTES.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH ATTRIBUTE DETAILS"
      })
    );
  }
}

export { fetchValidAttributesAsync };
