import initialState from 'store/initialState';
import { ITEM_CHANGES } from 'actions/actionTypes';

const itemChanges = (state = initialState.itemChanges, action) => {
  switch (action.type) {
    case ITEM_CHANGES.REQUEST:
      return { ...state, isSavingItemChanges: true };
    case ITEM_CHANGES.SUCCESS:
      return { ...state, isSavingItemChanges: false, itemChanges: action.payload.itemChanges.data };
    case ITEM_CHANGES.FAILURE:
      return { ...state, isSavingItemChanges: false };
    default:
      return state;
  }
};

export default itemChanges;
