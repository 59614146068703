import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as _ from 'lodash';

import AppLoader from 'components/AppLoader';
import * as actionCreators from '../actions';
import ItemDetailsTable from 'components/ItemDetailsTable';
import SearchFilters from 'components/SearchFilters';

export default function BrandConversionView() {
  const [selectedMainFilterValues, setSelectedMainFilterValues] = useState(null);
  const pairTypes = useSelector(state => _.get(state.pairTypes, 'pairTypes', []));
  // Define the function to be passed to SearchFilters
  const handleSearch = (payload) => {
    // Update state with selected values
    // setSelectedValues(values);
    setSelectedMainFilterValues(payload);
    console.log('at parent c' + payload);
    // Call the function in ItemDetailsTable
    if (itemDetailsTableFunction) {
      itemDetailsTableFunction(payload);
    }
  };

  // Define a function to receive the itemDetailsTableFunction from ItemDetailsTable
  const setItemDetailsTableFunction = func => {
    itemDetailsTableFunction = func;
  };
  let itemDetailsTableFunction;


  return (
    <React.Fragment>
      <div style={{width: '100%'}}>
        <SearchFilters />
        {!_.isEmpty(pairTypes) && <ItemDetailsTable />}
      </div>
    </React.Fragment>
  );
}
