import initialState from 'store/initialState';
import { DISTINCT_SUPC } from 'actions/actionTypes';

const supcList = (state = initialState.supcList, action) => {
  switch (action.type) {
    case DISTINCT_SUPC.REQUEST:
      return { ...state, isLoadingSupc: true };
    case DISTINCT_SUPC.SUCCESS:
      return {
        ...state,
        isLoadingSupc: false,
        supcList: { ...state.supcList, supcList: action.payload.supcList.data }
      };
    case DISTINCT_SUPC.FAILURE:
      return { ...state, isLoadingSupc: false };
    default:
      return state;
  }
};

export default supcList;
