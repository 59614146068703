import initialState from 'store/initialState';
import { VALID_ATTRIBUTES } from 'actions/actionTypes';

const validAttributes = (state = initialState.validAttributes, action) => {
  switch (action.type) {
    case VALID_ATTRIBUTES.REQUEST:
      return { ...state, isLoadingAttributes: true };
    case VALID_ATTRIBUTES.SUCCESS:
      return {
        ...state,
        isLoadingAttributes: false,
        validAttributes: { ...state.validAttributes, validAttributes: action.payload.attributes.data }
      };
    case VALID_ATTRIBUTES.FAILURE:
      return { ...state, isLoadingAttributes: false };
    default:
      return state;
  }
};

export default validAttributes;
