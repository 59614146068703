import { QUALIFIERS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';

// Get all qualifiers..
function* getQualifiersAsync() {
  try {
    const response = yield getRequest(`/brandconversion/qualifiers`);
    console.log('data', response);
    yield put({ type: QUALIFIERS.SUCCESS, payload: { response } });
  } catch (error) {
    console.log('inside api call error ', error?.response?.data?.description);
    yield put({ type: QUALIFIERS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error?.response?.data?.description,
        className: 'error',
        message: "COULDN'T LOAD QUALIFIER LIST"
      })
    );
  }
}

export { getQualifiersAsync };
