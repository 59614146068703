import initialState from 'store/initialState';
import { BUSINESS_CENTERS } from 'actions/actionTypes';

const businessCenters = (state = initialState.businessCenters, action) => {
  switch (action.type) {
    
    case BUSINESS_CENTERS.REQUEST:
      return { ...state, fetchingList: true  };
    case BUSINESS_CENTERS.SUCCESS:
      return { ...state, fetchingList: false,businessCenters: action.payload.businessCenters.data.data  };
    case BUSINESS_CENTERS.FAILURE:
      return { ...state, fetchingList: false };   
    default:
      return state;
  }
};

export default businessCenters;
