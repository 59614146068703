import initialState from 'store/initialState';
import { ATTRIBUTE_GROUPS } from 'actions/actionTypes';

const attributeGroups = (state = initialState.attributeGroups, action) => {
  switch (action.type) {
    case ATTRIBUTE_GROUPS.REQUEST:
      return { ...state, fetchingList: true };
    case ATTRIBUTE_GROUPS.SUCCESS:
      return { ...state, fetchingList: false, attributeGroups: action.payload.attributeGroups.data.data };
    case ATTRIBUTE_GROUPS.FAILURE:
      return { ...state, fetchingList: false };
    default:
      return state;
  }
};

export default attributeGroups;
