import { ITEM_GROUPS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';
// Gets all business centers
function* fetchItemGroupsAsync({bc}) {
  try {
    console.log('inside api call item groups');
    const itemGroups = yield getRequest(`/brandconversion/ig?bc=${bc}`);
    console.log('data', itemGroups);
    yield put({ type: ITEM_GROUPS.SUCCESS, payload: { itemGroups } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: ITEM_GROUPS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH ITEM GROUPS DETAILS"
      })
    );
  }
}

export { fetchItemGroupsAsync };
