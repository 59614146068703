import initialState from 'store/initialState';
import { QUALIFIERS } from 'actions/actionTypes';

const qualifiers = (state = initialState.qualifiers, action) => {
  switch (action.type) {
    case QUALIFIERS.REQUEST:
      return { ...state, isLoadingQualifiers: true };
    case QUALIFIERS.SUCCESS:
      return {
        ...state,
        isLoadingQualifiers: false,
        qualifiers:action.payload.response.data.data 
      };
    case QUALIFIERS.FAILURE:
      return { ...state, isLoadingQualifiers: false };
    default:
      return state;
  }
};

export default qualifiers;
