import { takeLatest } from 'redux-saga/effects';
import { ALL_BRANDS, DO_NOT_CONVERT_BRANDS, DO_NOT_CONVERT_BRANDS_ADD, DO_NOT_CONVERT_BRANDS_DELETE } from 'actions/actionTypes';
import { addDoNotConvertBrandsAsync, deleteDoNotConvertBrandsAsync, fetchDoNotConvertBrandsAsync, getAllBrandsAsync } from './doNotConvertBrandsSaga';

const doNotConvertBrandsSaga = [
  takeLatest(DO_NOT_CONVERT_BRANDS.REQUEST, fetchDoNotConvertBrandsAsync),
  takeLatest(DO_NOT_CONVERT_BRANDS_DELETE.REQUEST, deleteDoNotConvertBrandsAsync),
  takeLatest(ALL_BRANDS.REQUEST, getAllBrandsAsync),
  takeLatest(DO_NOT_CONVERT_BRANDS_ADD.REQUEST, addDoNotConvertBrandsAsync)
];
export default doNotConvertBrandsSaga;
