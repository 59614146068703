import { ITEMLIST, NEW_PAIR } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest, postRequest } from '_http';
import { action } from 'reduxHelpers';

// add new conversion pair..
function* addNewConversionPairAsync({ payload }) {
  
  try {
    const response = yield postRequest(`/brandconversion/pairs`, payload);
    console.log('data', response);
    yield put({ type: NEW_PAIR.SUCCESS, payload: { response } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'New conversion pair saved successfully.',
        className: 'success',
        message: 'SUCCESS: New Conversion Pair Saved'
      })
    );
  } catch (error) {
    console.log('inside api call error ', error?.response?.data?.description);
    yield put({ type: NEW_PAIR.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error?.response?.data?.description,
        className: 'error',
        message: "COULDN'T SAVE NEW CONVERSION PAIR"
      })
    );
  }
}

export { addNewConversionPairAsync };
