import initialState from 'store/initialState';
import { ITEM_GROUPS } from 'actions/actionTypes';

const itemGroups = (state = initialState.itemGroups, action) => {
  switch (action.type) {
    case ITEM_GROUPS.REQUEST:
      return { ...state, fetchingList: true };
    case ITEM_GROUPS.SUCCESS:
      return { ...state, fetchingList: false, itemGroups: action.payload.itemGroups.data.data };
    case ITEM_GROUPS.FAILURE:
      return { ...state, fetchingList: false };
    default:
      return state;
  }
};

export default itemGroups;
