const doNotConvertBrands = {
  fetchingDoNotConvertList: false,
  doNotConvertBrands: [],
  deletingBrands: false,
  fetchingAllBrands: false,
  allBrands: [],
  newDoNotConvertBrands: [],
  isAddingDoNotConvertBrand:false
};

export default doNotConvertBrands;
