import { MATCH_QUALIFIERS, QUALIFIERS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';

// Get all qualifiers..
function* getMatchQualifiersAsync({fromSupc,toSupc}) {
  try {
    const response = yield getRequest(`/brandconversion/qualifiers/matching?fromSupc=${encodeURIComponent(fromSupc)}&toSupc=${encodeURIComponent(toSupc)}`);
    console.log('data', response);
    yield put({ type: MATCH_QUALIFIERS.SUCCESS, payload: { response } });
  } catch (error) {
    console.log('inside api call error ', error?.response?.data?.description);
    yield put({ type: MATCH_QUALIFIERS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error?.response?.data?.description,
        className: 'error',
        message: "COULDN'T LOAD MATCHING QUALIFIER LIST"
      })
    );
  }
}

export { getMatchQualifiersAsync };
