import { BUSINESS_CENTERS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';
// Gets all business centers
function* fetchBusinessCentersAsync() {
  try {
    console.log('inside api call busniess centers');
    const businessCenters = yield getRequest(`/brandconversion/bc`);
    console.log('data', businessCenters);
    yield put({ type: BUSINESS_CENTERS.SUCCESS, payload: { businessCenters } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: BUSINESS_CENTERS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH BUSINESS CENTER DETAILS"
      })
    );
  }
}

export { fetchBusinessCentersAsync };
