import { PAIR_TYPES } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';


// Gets all business centers
function* fetchAllPairTypesAsync() {
  try {
    console.log('inside api call pair types');
    const pairTypes = yield getRequest(`/brandconversion/pair-types`);
    console.log('data pair types', pairTypes);
    yield put({ type: PAIR_TYPES.SUCCESS, payload: { pairTypes } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: PAIR_TYPES.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH PAIR TYPE DETAILS"
      })
    );
  }
}

export { fetchAllPairTypesAsync };
