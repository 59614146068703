import initialState from 'store/initialState';
import { ALL_BRANDS, DO_NOT_CONVERT_BRANDS, DO_NOT_CONVERT_BRANDS_ADD, DO_NOT_CONVERT_BRANDS_DELETE } from 'actions/actionTypes';

const doNotConvertBrands = (state = initialState.doNotConvertBrands, action) => {
  switch (action.type) {
    case DO_NOT_CONVERT_BRANDS.REQUEST:
      return { ...state, fetchingDoNotConvertList: true };
    case DO_NOT_CONVERT_BRANDS.SUCCESS:
      
      return {
        ...state,
        fetchingDoNotConvertList: false,
        doNotConvertBrands: {
          ...state.doNotConvertBrands,
          data: action.payload.nonConvertBrandList?.data?.data
        }
      };
    case DO_NOT_CONVERT_BRANDS.FAILURE:
      return { ...state, fetchingDoNotConvertList: false };

    case DO_NOT_CONVERT_BRANDS_DELETE.REQUEST:
      return { ...state, deletingBrands: true };
    case DO_NOT_CONVERT_BRANDS_DELETE.SUCCESS:
      return { ...state, deletingBrands: false };
    case DO_NOT_CONVERT_BRANDS_DELETE.FAILURE:
      return { ...state, deletingBrands: false };

    case ALL_BRANDS.REQUEST:
      return { ...state, fetchingAllBrands: true };
    case ALL_BRANDS.SUCCESS:
      return {
        ...state,
        fetchingAllBrands: false,
        allBrands: {
          ...state.allBrands,
          data: action.payload.brandList?.data?.data
        }
      };
    case ALL_BRANDS.FAILURE:
      return { ...state, fetchingAllBrands: false };


      case DO_NOT_CONVERT_BRANDS_ADD.REQUEST:
      return { ...state, isAddingDoNotConvertBrand: true };
    case DO_NOT_CONVERT_BRANDS_ADD.SUCCESS:
      return {
        ...state,
        isAddingDoNotConvertBrand: false,
        newDoNotConvertBrands: {
          ...state.newDoNotConvertBrands,
          data: action.payload.newDoNotConvertBrands?.data?.data
        }
      };
    case DO_NOT_CONVERT_BRANDS_ADD.FAILURE:
      return { ...state, isAddingDoNotConvertBrand: false };
    default:
      return state;
  }
};

export default doNotConvertBrands;
