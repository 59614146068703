import initialState from 'store/initialState';
import { COLUMN_SORTS, COLUMN_SORTS_REMOVE } from 'actions/actionTypes';

const columnSorters = (state = initialState.columnSorts, action) => {
  switch (action.type) {
    case COLUMN_SORTS.REQUEST:
      if (!Array.isArray(state)) {
        state = [];
      }
      const existingSorterIndex = state.findIndex(sort => sort.key === action.payload.key);
      if (existingSorterIndex !== -1) {
        // If a sorts with the same key exists, replace its value
        return [
          ...state.slice(0, existingSorterIndex), // Keep filters before the existing one
          { ...state[existingSorterIndex], value: action.payload.value }, // Replace the value
          ...state.slice(existingSorterIndex + 1) // Keep filters after the existing one
        ];
      } else {
        // If a sorts with the provided key doesn't exist, add it to the array
        return [...state, action.payload];
      }
    case COLUMN_SORTS_REMOVE.REQUEST:
      // Filter out the item with the provided key
      return state.filter(sort => sort.key !== action.payload.key);
    default:
      return state;
  }
};

export default columnSorters;
