import initialState from 'store/initialState';
import { MATCH_QUALIFIERS } from 'actions/actionTypes';

const matchQualifiers = (state = initialState.matchQualifiers, action) => {
  switch (action.type) {
    case MATCH_QUALIFIERS.REQUEST:
      return { ...state, isLoadingMatchQualifiers: true };
    case MATCH_QUALIFIERS.SUCCESS:
      return {
        ...state,
        isLoadingMatchQualifiers: false,
        matchQualifiers: action.payload.response.data.data
      };
    case MATCH_QUALIFIERS.FAILURE:
      return { ...state, isLoadingMatchQualifiers: false };
    default:
      return state;
  }
};

export default matchQualifiers;
