import initialState from 'store/initialState';
import { FILTER_PARAMS } from 'actions/actionTypes';

const filterParams = (state = initialState.filterParams, action) => {
  switch (action.type) {
    case FILTER_PARAMS.REQUEST:
      return { ...state, filterParams: { ...state.filterParams, ...action.payload } };
    default:
      return state;
  }
};

export default filterParams;
