import { ITEMLIST } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';


// Gets all business centers
function* fetchItemListAsync({bc,ig,ag,searchParams,sortParams,pageNo,pageSize}) {
  try {
    console.log('inside api call attribute groups');
    const _sortParams =
      sortParams && Object.keys(sortParams).length > 0
        ? encodeURIComponent(JSON.stringify(sortParams))
        : encodeURIComponent(JSON.stringify({ desc: ['fromSupc'] }));
    const _filter = searchParams ? encodeURIComponent(JSON.stringify(searchParams)) : encodeURIComponent(JSON.stringify({}));

    const itemList = yield getRequest(`/brandconversion/items?bc=${encodeURIComponent(bc)}&ig=${encodeURIComponent(ig)}&ag=${encodeURIComponent(ag)}&search-param=${_filter}&sort-params=${_sortParams}&current-page=${pageNo}&page-size=${pageSize}`);
    console.log('data', itemList);
    yield put({ type: ITEMLIST.SUCCESS, payload: { itemList } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: ITEMLIST.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH ITEM DETAILS"
      })
    );
  }
}

export { fetchItemListAsync };
