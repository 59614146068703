const user = {
  fetching: false,
  data: {
    email: null,
    contactId: null,
    name: null,
    validTill: null,
    roles: [],
    department: null,
    suvc: null,
    selectedVendor: null,
    permissions: []
  },
  error: null
};

export default user;
