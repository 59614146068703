import initialState from 'store/initialState';
import { PAIR_TYPES } from 'actions/actionTypes';

const pairTypes = (state = initialState.pairTypes, action) => {
  switch (action.type) {
    
    case PAIR_TYPES.REQUEST:
      return { ...state, isLoadingPairTypes: true  };
    case PAIR_TYPES.SUCCESS:
      return { ...state, isLoadingPairTypes: false,pairTypes: action.payload.pairTypes.data.data  };
    case PAIR_TYPES.FAILURE:
      return { ...state, isLoadingPairTypes: false };   
    default:
      return state;
  }
};

export default pairTypes;
