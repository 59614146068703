import { ITEM_CHANGES } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { postRequest } from '_http';
import { action } from 'reduxHelpers';

// Gets all business centers
function* saveItemChangesAsync({ payload }) {
  try {
    
    console.log('inside api call pair types');
    const itemChanges = yield postRequest(`/brandconversion/conversion-pairs/deleted-updated`, payload);
    console.log('data item changes', itemChanges);
    yield put({ type: ITEM_CHANGES.SUCCESS, payload: { itemChanges } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Changes are saved successfully.',
        className: 'success',
        message: 'SUCCESS: Changes are saved successfully.'
      })
    );
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: ITEM_CHANGES.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T SAVE ITEM CHANGES. "
      })
    );
  }
}

export { saveItemChangesAsync };
