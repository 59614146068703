import { DISTINCT_SUPC } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';

// Gets all business centers
function* fetchDistinctSupcListAsync({ searchPattern, searchValue, brandType, sortParams, pageNo, pageSize }) {
  try {
    console.log('inside api call attribute groups');
    const _sortParams =
      sortParams && Object.keys(sortParams).length > 0
        ? encodeURIComponent(JSON.stringify(sortParams))
        : encodeURIComponent(JSON.stringify({ desc: ['supc'] }));

    const supcList = yield getRequest(
      `/brandconversion/supc?searchPattern=${encodeURIComponent(searchPattern)}&searchValue=${encodeURIComponent(
        searchValue
      )}&brandType=${encodeURIComponent(
        brandType
      )}&sort-params=${_sortParams}&current-page=${pageNo}&page-size=${pageSize}`
    );
    console.log('data', supcList);
    yield put({ type: DISTINCT_SUPC.SUCCESS, payload: { supcList } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: DISTINCT_SUPC.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH SUPC DETAILS"
      })
    );
  }
}

export { fetchDistinctSupcListAsync };
