import React from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import i18n from './_i18n';
import MainApp from './MainApp';

function App(props) {
  const {
    selectedVendor,
    language,
    Auth: {
      user: { username }
    }
  } = props;
  i18n.changeLanguage(language);

  return (
    <Provider store={store}>
      <MainApp selectedVendor={selectedVendor} userId={username} />
    </Provider>
  );
}

export default App;
