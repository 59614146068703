import initialState from 'store/initialState';
import { COLUMN_FILTERS, COLUMN_FILTERS_REMOVE } from 'actions/actionTypes';

const columnFilters = (state = initialState.columnFilters, action) => {
  switch (action.type) {
    case COLUMN_FILTERS.REQUEST:
      if (!Array.isArray(state)) {
        state = [];
      }
      const existingFilterIndex = state.findIndex(filter => filter.key === action.payload.key);
      if (existingFilterIndex !== -1) {
        // If a filter with the same key exists, replace its value
        return [
          ...state.slice(0, existingFilterIndex), // Keep filters before the existing one
          { ...state[existingFilterIndex], value: action.payload.value }, // Replace the value
          ...state.slice(existingFilterIndex + 1) // Keep filters after the existing one
        ];
      } else {
        // If a filter with the provided key doesn't exist, add it to the array
        return [...state, action.payload];
      }
    case COLUMN_FILTERS_REMOVE.REQUEST:
      // Filter out the item with the provided key
      return state.filter(filter => filter.key !== action.payload.key);
    default:
      return state;
  }
};

export default columnFilters;
