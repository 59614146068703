import initialState from 'store/initialState';
import { ITEMLIST } from 'actions/actionTypes';

const itemList = (state = initialState.itemList, action) => {
  switch (action.type) {
    case ITEMLIST.REQUEST:
      return { ...state, fetchingList: true };
    case ITEMLIST.SUCCESS:
      return {
        ...state,
        fetchingList: false,
        allItemList: { ...state.allItemList, allItemList: action.payload.itemList.data }
      };
    case ITEMLIST.FAILURE:
      return { ...state, fetchingList: false };
    default:
      return state;
  }
};

export default itemList;
