import initialState from 'store/initialState';
import { NEW_PAIR, VALIDATE_PAIR } from 'actions/actionTypes';

const validateNewPair = (state = initialState.validNewPair, action) => {
  switch (action.type) {
    case VALIDATE_PAIR.REQUEST:
      return { ...state, isValidPair: false,isChecking: true };
    case VALIDATE_PAIR.SUCCESS:
      return {
        ...state, 
        isValidPair: action.payload.response.data.data ,
        isChecking: false
      };
    case VALIDATE_PAIR.FAILURE:
      return { ...state, isValidPair: false,isChecking: false};
    default:
      return state;
  }
};

export default validateNewPair;
