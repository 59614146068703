import { ATTRIBUTE_GROUPS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';


// Gets all business centers
function* fetchAttributeGroupsAsync({bc,ig}) {
  try {
    console.log('inside api call attribute groups');
    const attributeGroups = yield getRequest(`/brandconversion/ag?bc=${encodeURIComponent(bc)}&ig=${encodeURIComponent(ig)}`);
    console.log('data', attributeGroups);
    yield put({ type: ATTRIBUTE_GROUPS.SUCCESS, payload: { attributeGroups } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: ATTRIBUTE_GROUPS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH ATTRIBUTE GROUPS DETAILS"
      })
    );
  }
}

export { fetchAttributeGroupsAsync };
